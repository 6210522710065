import $ from "jquery";
import select2, { QueryOptions } from "select2";
import { PaginatedResponse } from "./types";
interface Args {
  placeholder?: string | null;
  theme?: string | null;
}

/* @ts-ignore */
select2($);
export default function loadPortOptions(args: Args = {}): void {
  $(".port-select").select2({
    placeholder: args.placeholder || "Select",
    theme: args.theme || "bootstrap4",
    ajax: {
      url: "/app/ports",
      dataType: "json",
      delay: 250,
      headers: {
        "x-csrf-token": $("meta[name='csrf-token']").attr("content"),
      },
      data: (params: QueryOptions) => ({
        name: params.term,
        page: params.page || 1,
      }),
      processResults: (data: PaginatedResponse) => ({
        results: data.entries,
        pagination: {
          more: data.total_pages > data.page_number,
        },
      }),
      cache: true,
    },
  });
}
