import $ from "jquery";
import select2 from "select2";
/* @ts-ignore */
select2($);
export default function select2Selectors() {
  $("[data-init-plugin='select2']:not(.ignore-select2)").each(function (
    this: any,
  ) {
    const placeholder = $(this).attr("placeholder") || "Select";
    const allowClear = $(this).data("allow-clear") === false ? false : true;
    const allowTags = $(this).data("allow-tags") === true ? true : false;
    const addFlag = $(this).data("add-flag") === true ? true : false;
    const theme = $(this).data("theme");
    const dropdownParent = $(this).data("dropdown-parent-selector") || "body";
    let notUnselectable = $(this).data("force-selected") || "";
    notUnselectable =
      String(notUnselectable) === "" ? [] : String(notUnselectable).split(",");

    notUnselectable =
      String(notUnselectable) === "" ? [] : String(notUnselectable).split(",");
    $(this)
      .select2({
        tags: allowTags,
        createTag: (params: any) => {
          return {
            id: params.term,
            text: params.term,
            newOption: true,
          };
        },
        templateResult: (data: any) => {
          let $result: any;
          if (addFlag) {
            $result = renderSelectionWithFlag(data);
          } else {
            $result = renderDefaultSelection(data);
          }

          if (data.newOption) {
            $result.append(" <em>(new)</em>");
          }

          return $result;
        },
        templateSelection: (data: any) => {
          let $result: any;
          if (addFlag) {
            $result = renderSelectionWithFlag(data);
          } else {
            $result = renderDefaultSelection(data);
          }
          return $result;
        },
        dropdownParent: $(`${dropdownParent}`),
        theme: theme,
        placeholder: placeholder,
        allowClear: allowClear,
      })
      .on("select2:unselecting", function (this: any, e: any) {
        if (notUnselectable.includes(e.params.args.data.id) === true) {
          return false;
        }
        $(this).data("unselecting", true);
      })
      .on("select2:open", function (this: any, _e: any) {
        if ($(this).data("unselecting")) {
          $(this).select2("close").removeData("unselecting");
        }
      });
  });
}

function renderDefaultSelection(data: any) {
  return $("<span>" + data.text + "</span>");
}

function renderSelectionWithFlag(data: any) {
  if (data.loading || data.id === "") {
    return renderDefaultSelection(data);
  } else {
    const flagCode = data.text.split(" * ")[0];
    const text = data.text.split(" * ")[1];

    return $(
      `
        <span><span class="flag:${flagCode} mr-2"></span>${text}</span>
      `,
    );
  }
}
