// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html";
// Establish Phoenix Socket and LiveView configuration.
import { LiveSocket } from "phoenix_live_view";
import { Socket } from "phoenix";
import "../../../martide_components/assets/js/components/plausible.js";
import topbar from "topbar";
import "flowbite";
import MartideHooks from "./martide/live_hook.js";

declare var window: any;

if (typeof window.queueMicrotask !== "function") {
  window.queueMicrotask = (callback: any) => {
    Promise.resolve()
      .then(callback)
      .catch((e) =>
        setTimeout(() => {
          throw e;
        }),
      );
  };
}

function handleDOMContentLoaded() {
  const forms = document.querySelectorAll(
    "form input, form select, form textarea",
  );

  forms.forEach((form) => {
    form.addEventListener("change", () => {
      form.classList.add("form-dirty");
    });

    window.addEventListener("beforeunload", () => {
      if (
        !form.classList.contains("ignore-changes") &&
        form.classList.contains("form-dirty")
      ) {
        return "You have unsaved changes, are you sure you want to discard them?";
      }
    });

    form.addEventListener("submit", () => {
      form.classList.remove("form-dirty");
    });
  });
}

function handleDocumentUnload() {
  window.currentView.unmount();
}

window.addEventListener("DOMContentLoaded", handleDOMContentLoaded, false);
window.addEventListener("unloaded", handleDocumentUnload, false);

const Hooks = { Select: MartideHooks.Select };

let csrfToken = document
  ?.querySelector("meta[name='csrf-token']")
  ?.getAttribute("content");

if (csrfToken) {
  let liveSocket = new LiveSocket("/live", Socket, {
    hooks: Hooks,
    params: { _csrf_token: csrfToken },
  });

  // connect if there are any LiveViews on the page
  liveSocket.connect();

  // expose liveSocket on window for web console debug logs and latency simulation:
  // >> liveSocket.enableDebug()
  // >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
  // >> liveSocket.disableLatencySim()
  window.liveSocket = liveSocket;
}

// Show progress bar on live navigation and form submits
topbar.config({ barColors: { 0: "#29d" }, shadowColor: "rgba(0, 0, 0, .3)" });
window.addEventListener("phx:page-loading-start", () => topbar.show());
window.addEventListener("phx:page-loading-stop", () => topbar.hide());
