import "jquery-serializejson";
import "select2";
import AdminHooks from "../admin/hooks.js";
import clean_blank_file_input from "../views/form_helpers/clean_blank_file_input";
import loadAirportOptions from "../views/form_helpers/load_airports";
import loadPortOptions from "../views/form_helpers/load_ports";
import Select2 from "../views/form_helpers/select2_selectors";
import Sortable from "sortablejs";
import topbar from "topbar";

topbar.config({ barColors: { 0: "#29d" }, shadowColor: "rgba(0, 0, 0, .3)" });

declare let $: any;
declare var window: any;

interface IFlashMessage {
  type: string;
  message: string;
  title: string;
}

const Hooks = {
  Accordion: AdminHooks.Accordion,
  ApplyButtonState: {},
  AutoScrollLock: {},
  SimpleFormSubmit: {},
  BeforeUnload: {},
  Confirm: {},
  ConfirmLiveNav: {},
  ConfirmOnChange: {},
  CrewChangeForm: {},
  DatePicker: AdminHooks.DatePicker,
  DisableListNotification: {},
  Dropdown: AdminHooks.Dropdown,
  ExpenseForm: {},
  Filter: AdminHooks.Filter,
  HandleCopyToClipBoard: {},
  HandleDashboardSelect: {},
  HandleDataTemplateTable: {},
  HandleFilterDropdown: {},
  HandleFormLoading: {},
  HandleLocationFilter: {},
  HandleMultiSelect: {},
  HandleNotificationMenuItem: {},
  HandlePageLoading: {},
  HandleSelectOption: {},
  HandleSelectOptionForm: {},
  HandleSortPosition: {},
  HandleDragNDrop: {},
  HandleSubmitFormAttachment: {},
  HandleUpdateRecordsPosition: {},
  HandleValidateMultipleSelect: {},
  InitAirportsSelect2: {},
  InitFeather: {},
  InitSelect2: {},
  InvoiceForm: {},
  PersonalInfoForm: {},
  PushEvent: {},
  RemoveNotification: {},
  Search: AdminHooks.Search,
  ScrollBottom: {},
  ScrollLock: {},
  ScrollTop: {},
  Select: AdminHooks.Select,
  Tooltip: AdminHooks.Tooltip,
  UpdateAlert: {},
  UpdateNotificationBadge: {},
  ValidateForm: {},
  EditorJS: AdminHooks.EditorJS,
};

const updateFlash = (props: IFlashMessage) => {
  const { type, message, title } = props;

  window.dispatchEvent(
    new CustomEvent("flash", {
      detail: { type: type, message: message, title: title },
    }),
  );
};

// Identify which button was clicked on form submission.
Hooks.ApplyButtonState = {
  mounted(this: any) {
    const target = this.el.dataset.target || "tracking-submit-input";
    const hiddenInput = $(`#${target}`);
    this.el.addEventListener("mouseenter", (e: any) => {
      hiddenInput.val(e.target.getAttribute("name"));
    });
  },
};

// Place this hook on the modal's root element to automatically
// lock the scroll when the modal is opened and unlock if it is
// closed
Hooks.AutoScrollLock = {
  lockScroll(lock: boolean) {
    let event = new CustomEvent("scroll-lock", { detail: lock });
    window.dispatchEvent(event);
  },
  mounted(this: any) {
    this.lockScroll(true);
  },
  destroyed(this: any) {
    this.lockScroll(false);
  },
  disconnected(this: any) {
    this.lockScroll(false);
  },
};

Hooks.BeforeUnload = {
  mounted(this: any): void {
    const formInputs = Array.from(
      document.querySelectorAll("form input, form select, form textarea"),
    ) as HTMLElement[];

    formInputs.forEach((input: HTMLElement) => {
      input.addEventListener("change", () => {
        const form = input.closest("form");
        if (form) {
          form.classList.add("form-dirty");
        }
      });
    });

    window.addEventListener("beforeunload", (event: BeforeUnloadEvent) => {
      const dirtyForms = Array.from(
        document.querySelectorAll("form:not(.ignore-changes).form-dirty"),
      );
      if (dirtyForms.length > 0) {
        event.returnValue =
          "You have unsaved changes, are you sure you want to discard them?";
      }
    });

    const forms = Array.from(
      document.querySelectorAll("form"),
    ) as HTMLElement[];

    forms.forEach((form: HTMLElement) => {
      form.addEventListener("submit", () => {
        form.classList.remove("form-dirty");
      });
    });
  },
};

/*
Place this hook to pop up a confirmation dialog when an element is clicked
A `phx-confirm` attribute is required to set the message to be displayed.

To conditionally prevent the popup, you may add a `phx-no-confirm` attribute to the element.

Examples:
```
<button
  phx-click="do_something"
  phx-hook="Confirm"
  phx-confirm="Are you sure you want to do something?">
  Do something
</button>

<button
  phx-click="maybe_confirm"
  phx-hook="Confirm"
  phx-confirm="Are you sure you want to do something?"
  phx-no-confirm={some_boolean}>
  Maybe confirm
</button>
```
*/
Hooks.Confirm = {
  openDialog(this: any, message: string) {
    const modal = document.getElementById("confirmation-popup");
    if (modal) {
      modal.querySelector("[data-popup-message]")!.textContent = message;
      window.liveSocket.execJS(modal, modal.getAttribute("data-exec-open"));
    }
  },
  mounted(this: any) {
    const element = this.el;
    const message = element.getAttribute("phx-confirm");
    if (!message) {
      throw new Error("Attribute phx-confirm is not set");
    }
    element.addEventListener("click", (e: any) => {
      const preventConfirm = e.target.hasAttribute("phx-no-confirm");
      if (preventConfirm) return;
      if (e.isTrusted) {
        e.stopPropagation();
        e.preventDefault(); // To prevent submits for submit buttons
        const callback = () => {
          e.target.dispatchEvent(new PointerEvent("click", { bubbles: true }));
        };
        this.openDialog(message);
        window.addEventListener("phx:confirm", callback, { once: true });
        window.addEventListener("phx:cancel", () => {
          window.removeEventListener("phx:confirm", callback);
        });
      }
    });
  },
};

Hooks.ConfirmOnChange = {
  openDialog(this: any, message: string) {
    const modal = document.getElementById("confirmation-popup");
    if (modal) {
      modal.querySelector("[data-popup-message]")!.textContent = message;
      window.liveSocket.execJS(modal, modal.getAttribute("data-exec-open"));
    }
  },
  mounted(this: any) {
    const element = this.el;
    const message = element.getAttribute("phx-confirm");
    const targetFieldIds = JSON.parse(element.dataset.targetFieldIds);
    if (!message) {
      throw new Error("Attribute phx-confirm is not set");
    }

    targetFieldIds.forEach((field: string) => {
      const input = <any>document.querySelector(`#${field}`);

      this[field] = input.value;

      // Support select for now
      if (input && input.tagName === "SELECT") {
        // Save previous value for cancelling
        input.addEventListener("change", (e: any) => {
          // Change if value select
          if (input.value !== this[field]) {
            e.stopPropagation();
            e.preventDefault();

            const callback = () => {
              this[field] = input.value;
              input.dispatchEvent(new Event("change", { bubbles: true }));
            };

            this.openDialog(message);

            window.addEventListener("phx:confirm", callback, { once: true });

            window.addEventListener(
              "phx:cancel",
              () => {
                window.removeEventListener("phx:confirm", callback);
                // Revert select value
                if (input.tomselect) {
                  input.tomselect.setValue(this[field]);
                } else {
                  input.value = this[field];
                }
              },
              { once: true },
            );
          }
        });

        // Ignore input event to not trigger phx-change
        input.addEventListener("input", (e: any) => {
          e.stopPropagation();
          e.preventDefault();
        });
      }
    });
  },
};

Hooks.ConfirmLiveNav = {
  mounted(this: any) {
    var currentLocation = window.location.toString();
    const confirmMessage =
      "Are you sure you want to leave without saving your changes?";

    window.stopPopState = function () {
      const newLocation = window.location.toString();
      if (
        !$("form:not(.ignore-changes).form-dirty").length ||
        newLocation === currentLocation
      ) {
        return false;
      }
      return !confirm(confirmMessage);
    };

    window.stopLiveNav = function (toLocation: string) {
      if (
        !$("form:not(.ignore-changes).form-dirty").length ||
        toLocation === currentLocation
      ) {
        return false;
      }
      return !confirm(confirmMessage);
    };
  },
  destroyed() {
    window.stopPopState = null;
    window.stopLiveNav = null;
  },
};

Hooks.CrewChangeForm = {
  setSelectListener(this: any, selector: string) {
    $(selector).on("change", () => {
      document
        .querySelector(selector)
        ?.dispatchEvent(new Event("input", { bubbles: true }));
    });
  },
  mounted(this: any) {
    Select2();
    loadAirportOptions();
    loadPortOptions();

    this.setSelectListener("#crew_change_status");
    this.setSelectListener("#crew_change_airport_iata_code");
    this.setSelectListener("#crew_change_port_locode");
    this.setSelectListener("#crew_change_person_in_charge_id");
    this.setSelectListener("#crew_change_country_ids_select");

    this.handleEvent("unsaved_changes", () => {
      $(this.el).addClass("form-dirty");
    });
    this.handleEvent("changes_saved", () => {
      $(this.el).removeClass("form-dirty");
    });

    $("#delete_crew_change").on("click", () => {
      let confirmation = confirm(
        "Are you sure you want to delete this crew change?",
      );

      if (confirmation) {
        $(this.el).removeClass("form-dirty");
      }

      return confirmation;
    });
  },
  updated() {
    loadAirportOptions();
  },
};

Hooks.DisableListNotification = {
  mounted(this: any) {
    const menu = document.querySelector(".dropdown");

    document.addEventListener("mouseup", (e: MouseEvent) => {
      if (menu && !(menu as HTMLElement).contains(e.target as Node)) {
        this.pushEvent("disable_list_notification", { is_active: false });
      }
    });
  },
};

Hooks.ExpenseForm = {
  setSelectListener(this: any, selector: string) {
    $(selector).on("change", () => {
      document
        .querySelector(selector)
        ?.dispatchEvent(new Event("input", { bubbles: true }));
    });
  },
  mounted(this: any) {
    Select2();
    this.setSelectListener(
      "select[id^='invoice-form_expenses'][id$='_currency_code']",
    );
    this.setSelectListener(
      "select[id^='invoice-form_expenses'][id$='_product_type_id']",
    );
    this.handleEvent("remove_seafarer", ({ id, target }: any) => {
      this.pushEventTo(target, "remove_seafarer", { id: id });
    });
  },
  updated() {
    Select2();
  },
};

// Reference: https://hexdocs.pm/phoenix_live_view/js-interop.html#live-navigation-events
Hooks.HandlePageLoading = {
  mounted(this: any) {
    window.addEventListener("phx:page-loading-start", () => topbar.show());
    window.addEventListener("phx:page-loading-stop", (e: CustomEvent) => {
      topbar.hide();
      if (e.detail.kind === "patch") window.scrollTo({ top: 0 });
    });
  },
};

// event to initialize select2 in dashboard
Hooks.HandleDashboardSelect = {
  mounted(this: any) {
    Select2();
    const select = $(this.el).find("select");
    select.on("select2:select", (e: any) => {
      this.pushEvent("company_selected", { company_id: e.params.data.id });
    });
  },
};

// Push `select2_changed` when select2 was closed.
Hooks.HandleSelectOption = {
  mounted(this: any) {
    $(this.el).on("select2:close", (e: any) => {
      const targetTo = $(e.target).attr("phx-target");
      if (targetTo) {
        this.pushEventTo(targetTo, "select2_changed", {
          id: $(this.el).attr("id"),
          text: $(this.el).find(":selected").text(),
          selected: $(this.el).val(),
        });
      } else {
        this.pushEvent("select2_changed", {
          id: $(this.el).attr("id"),
          text: $(this.el).find(":selected").text(),
          selected: $(this.el).val(),
        });
      }
    });
    this.handleEvent("change_options", ({ id, options, prompt }: any) => {
      if ($(this.el).attr("id") === id) {
        $(this.el).empty();

        if (prompt === "" || prompt) {
          $(this.el).append(new Option(prompt, "", true, true));
        }

        options.forEach((option: any) => {
          var newOption = new Option(option.key, option.value, false, false);
          $(this.el).append(newOption);
        });

        $(this.el).trigger("change");
      }
    });
  },
};

Hooks.HandleValidateMultipleSelect = {
  mounted(this: any) {
    const _this = this;
    $(_this.el).on("change", (_e: any) => {
      const formId = this.el.dataset.form || "broadcast-form";
      const form = $(`form#${formId}`).serializeJSON();
      const targetId = this.el.getAttribute("phx-target");
      const target = document.getElementById(targetId);
      if (target) {
        this.pushEventTo(target, "validate_form", form);
      } else {
        this.pushEvent("validate_form", form);
      }
    });
  },
};

Hooks.HandleMultiSelect = {
  mounted(this: any) {
    const _this = this;
    $(_this.el).on("change", (e: any) => {
      _this.pushEvent("multiple_select_changed", {
        target: _this.el.dataset.target,
        value: $(e.target).val(),
      });
    });
  },
};

Hooks.HandleDataTemplateTable = {
  beforeUpdate(this: any): void {
    const collapsedTargetElements = Array.from(
      document.querySelectorAll(".document-type-category-list.collapsed"),
    ) as HTMLElement[];

    const collapsedTargetClasses = collapsedTargetElements.map(
      (element: HTMLElement) => element.getAttribute("data-target"),
    );

    Object.assign(this, { collapsedTargetClasses });
  },
  updated(this: any): void {
    this.collapsedTargetClasses.forEach((target: string) => {
      const tr = document.querySelector(
        `.document-type-category-list[data-target='${target}']`,
      );

      if (tr) {
        tr.classList.toggle("collapsed");
        tr.setAttribute(
          "aria-expanded",
          String(!(tr.getAttribute("aria-expanded") === "true")),
        );

        const targetElement = document.querySelector(target);
        if (targetElement) {
          targetElement.classList.toggle("show");
        }
      }
    });
  },
};

Hooks.HandleNotificationMenuItem = {
  mounted(this: any) {
    const expanded: boolean = $("#sidebar").attr("aria-expanded") === "true";

    if (!expanded) {
      $(this.el).find("span:not(.notify-badge)").toggleClass("hidden");
    }
  },
};

Hooks.HandleSelectOptionForm = {
  mounted(this: any) {
    $(this.el).on("select2:close", (e: any) => {
      const targetTo = $(e.target).attr("phx-target");
      const formData = ($("form#form-select-options") as any).serializeJSON();
      if (targetTo) {
        this.pushEventTo(targetTo, "validate", formData);
      } else {
        this.pushEvent("validate", formData);
      }
    });
  },
};

Hooks.HandleSortPosition = {
  mounted(this: any) {
    const list_stages = document.getElementById("list-stages");
    !!list_stages &&
      Sortable.create(list_stages, {
        handle: ".handle-bar",
        onUpdate: (event) =>
          this.pushEvent("update_position", {
            data: Array.from(event.to.children).map((c) => c.id),
          }),
      });
  },
  updated(this: any) {
    const list_stages = document.getElementById("list-stages");
    !!list_stages &&
      Sortable.create(list_stages, {
        handle: ".handle-bar",
        onUpdate: (event) =>
          this.pushEvent("update_position", {
            data: Array.from(event.to.children).map((c) => c.id),
          }),
      });
  },
};

Hooks.HandleDragNDrop = {
  mounted(this: any) {
    const dragNDropList = document.querySelector(
      '[data-sort="drag-n-drop-list"]',
    ) as HTMLElement;
    !!dragNDropList &&
      Sortable.create(dragNDropList, {
        handle: ".sort-menu",
      });
  },
  updated(this: any) {
    const dragNDropList = document.querySelector(
      '[data-sort="drag-n-drop-list"]',
    ) as HTMLElement;
    !!dragNDropList &&
      Sortable.create(dragNDropList, {
        handle: ".sort-menu",
      });
  },
};

Hooks.HandleUpdateRecordsPosition = {
  mounted(this: any) {
    this.el.addEventListener("click", (_e: any) => {
      var elements = document.querySelectorAll("[data-order-id]");
      var orderIds = Array.from(elements).map(function (element) {
        return element.getAttribute("data-order-id");
      });
      !!elements &&
        this.pushEvent("update_records_position", {
          data: orderIds,
        });
    });
  },
};

Hooks.HandleFilterDropdown = {
  mounted(this: any) {
    const toggleFilterDropdown = ({ open }: any) => {
      var event = new CustomEvent("toggle-filter-dropdown", { detail: open });
      window.dispatchEvent(event);
    };
    this.handleEvent("toggle_filter_dropdown", toggleFilterDropdown);
  },
};

Hooks.HandleSubmitFormAttachment = {
  submitForm(this: any) {
    $(this.el).on("submit", (e: any) => {
      const _this = this;
      e.preventDefault();
      let form = e.target;
      form = clean_blank_file_input(form);
      const formData = new FormData(form);
      const files = $(form).find("input[type='file']").toArray();
      const submitBtn = $(form).find("button[type='submit']");
      files.forEach((file: any) => formData.append(file.name, file.files[0]));
      $(submitBtn)
        .html("<i data-feather='loader' class=''></i> Saving...")
        .attr("disabled", true);
      $.ajax({
        url: form.action,
        type: form.method,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          X_CSRF_TOKEN: $(this).find("input[name='_csrf_token']").val(),
        },
      })
        .done((_response: any) => {
          this.pushEvent("update_vessel");
        })
        .fail((err: any) => {
          if (err.responseJSON.html) {
            $(_this.el).parent().html(err.responseJSON.html);
            Select2();
          } else {
            console.log("Update failed");
          }
        });
    });
  },
  mounted(this: any) {
    this.submitForm(this);
  },
  updated(this: any) {
    this.submitForm(this);
  },
};

Hooks.HandleFormLoading = {
  loadingListener(this: any) {
    const submitBtn = $(this.el).find("button[type='submit']");
    const currentLabel = submitBtn.text();
    const loadingLabel = $(submitBtn).attr("phx-disable-with") || "Saving...";
    this.handleEvent("start_loading", () => {
      $(submitBtn).html(loadingLabel).attr("disabled", true);
    });

    this.handleEvent("stop_loading", () => {
      $(submitBtn).html(currentLabel).attr("disabled", false);
    });
  },
  mounted(this: any) {
    this.loadingListener();
  },
  updated(this: any) {
    this.loadingListener();
  },
};

Hooks.HandleLocationFilter = {
  mounted(this: any) {
    const select = $(this.el).find("#form-location_type");
    const hiddenInput = $(this.el).find("#form-location_company_id");
    select.on("select2:select", (_e: any) => {
      $(hiddenInput).prop("disabled", false);
    });
    select.on("select2:clear", (_e: any) => {
      $(hiddenInput).prop("disabled", true);
    });
  },
};

/*
Use for copying text to clipboard by a click of a button or link

You may use `data-copy-target` attribute to specify the target element to copy the text from.
Example:
  <textarea id={"text-container"} rows="9" disabled class="hidden">
    Text to copy
  </textarea>

  <button
    phx-hook="HandleCopyToClipBoard"
    id="some-id"
    data-copy-target={"text-container"}
    ...
  >
    Copy to clipboard
  </button>

Or use a class text-widget if you don't want to specify the target element.
Example:
  <textarea class="textarea-widget" rows="9" disabled class="hidden">
    Text to copy
  </textarea>

  <button
    phx-hook="HandleCopyToClipBoard"
    id="some-id"
    ...
  >
    Copy to clipboard
  </button>
*/
Hooks.HandleCopyToClipBoard = {
  copyTextNavigator(element: any) {
    const value = element.value;
    navigator.clipboard.writeText(value).then(
      () => {
        updateFlash({
          type: "success",
          message: "Copied to clipboard",
          title: "Success",
        });
      },
      (_err) => {
        updateFlash({
          type: "error",
          message: "Could not be copied to clipboard",
          title: "Sorry",
        });
      },
    );
  },
  copyTextDocument(element: any) {
    try {
      element.disabled = false;
      element.select();
      if (document.execCommand("copy")) {
        updateFlash({
          type: "success",
          message: "Copied to clipboard",
          title: "Success",
        });
      } else {
        updateFlash({
          type: "error",
          message: "Could not be copied to clipboard",
          title: "Sorry",
        });
      }
      element.disabled = true;
    } catch (err) {
      updateFlash({
        type: "error",
        message: "Could not be copied to clipboard",
        title: "Sorry",
      });
    }
  },
  copyTextFromElement(this: any, element: any) {
    if (!navigator.clipboard) {
      this.copyTextDocument(element);
    } else {
      this.copyTextNavigator(element);
    }
  },
  mounted(this: any) {
    this.el.addEventListener("click", (e: any) => {
      // stop propagation and prevent default so copy button can be placed inside links
      e.stopPropagation();
      e.preventDefault();

      const copyTarget = this.el.getAttribute("data-copy-target");
      let widgetTextarea = copyTarget
        ? document.getElementById(copyTarget)
        : document.querySelector(".textarea-widget");

      this.copyTextFromElement(widgetTextarea);
    });

    this.handleEvent("copy_to_clipboard", (data: any) => {
      let widgetTextarea = document.querySelector(data.copy_target);
      this.copyTextFromElement(widgetTextarea);
    });
  },
};

Hooks.InitAirportsSelect2 = {
  mounted() {
    loadAirportOptions();
  },
  updated() {
    loadAirportOptions();
  },
};

Hooks.InitFeather = {
  initFeather() {
    const feather = require("feather-icons");
    feather.replace();
  },
  mounted(this: any) {
    this.initFeather();
  },
  updated(this: any) {
    this.initFeather();
  },
};

// init select2
Hooks.InitSelect2 = {
  stopPropagation() {
    $("select").on("select2:unselect", function (evt: any) {
      if (!evt.params.originalEvent) {
        return;
      }
      evt.params.originalEvent.stopPropagation();
    });
  },
  dispatchInputEvent(this: any) {
    this.el
      .querySelectorAll("select[data-select2-id]")
      .forEach((select: HTMLSelectElement) => {
        $(select).on("change", (e: Event) => {
          if (e.target) {
            e.target.dispatchEvent(new Event("input", { bubbles: true }));
          }
        });
      });
  },
  mounted(this: any) {
    this.stopPropagation();
    Select2();
    this.dispatchInputEvent();
  },
  updated(this: any) {
    this.stopPropagation();
    Select2();
    this.dispatchInputEvent();
  },
};

Hooks.InvoiceForm = {
  currentFormQuery: null,
  setSelectListener(this: any, selector: string) {
    $(selector).on("change", () => {
      document
        .querySelector(selector)
        ?.dispatchEvent(new Event("input", { bubbles: true }));
    });
  },
  mounted(this: any) {
    this.setSelectListener("#invoice-form_vessel_id", "vessel_id");
    this.setSelectListener("#invoice-form_currency_code", "currency_code");
    this.setSelectListener("#invoice-form_payment_terms", "payment_terms");
    this.handleEvent("select_option", ({ id, option }: any) => {
      $(`#${id}`).val(option);
      $(`#${id}`).trigger("change");
    });
    this.handleEvent("verify_form_changes", () => {
      let changed: any = {};
      this.currentFormQuery !== this.getIncomingFormQuery(this)
        ? (changed.value = true)
        : (changed.value = false);
      this.pushEvent("form_changed", changed);
    });
    this.handleEvent("save_current_form_query", () => {
      this.currentFormQuery = this.getIncomingFormQuery(this);
    });
    window.addEventListener("beforeunload", function (e: any) {
      if ($("form[phx-form-dirty]").length > 0) {
        e.preventDefault();
        e.returnValue = "";
      }
    });
  },
  reconnected(this: any) {
    const vesselId = $("#invoice-form_vessel_id").val();
    const invoiceDate = $("#invoice-form_invoice_date").val();
    this.pushEventTo(
      "#confirmations-table",
      "prelist_confirmations_after_reconnect",
      { vessel_id: vesselId, invoice_date: invoiceDate },
    );
  },
  getIncomingFormQuery(this: any) {
    let form = this.el.closest("form");
    let formData: any = new FormData(form);
    formData.delete("invoice[vessel_id]");
    formData.delete("invoice[invoice_date]");
    let queryString = new URLSearchParams(formData).toString();
    return queryString;
  },
};

Hooks.PersonalInfoForm = {
  mounted(this: any) {
    Select2();
    loadAirportOptions();
  },
  updated(this: any) {
    Select2();
    loadAirportOptions();
  },
};

Hooks.PushEvent = {
  mounted() {
    window.pushEventHook = this;
  },
};

Hooks.RemoveNotification = {
  mounted(this: any) {
    this.el.addEventListener("click", (_e: any) => {
      const notification_id = $(this.el).attr("phx-value-notification_id");
      window.removeNotification(notification_id);
      this.pushEvent("remove_notification", {
        notification_id: notification_id,
      });
    });
  },
};

// event autoscroll to last message to message box
Hooks.ScrollBottom = {
  mounted(this: any) {
    this.el.scrollTop = this.el.scrollHeight;
  },
  updated(this: any) {
    this.el.scrollTop = this.el.scrollHeight;
  },
};

Hooks.ScrollLock = {
  mounted(this: any) {
    const handleScrollLock = ({ lock }: any) => {
      var event = new CustomEvent("scroll-lock", { detail: lock });
      window.dispatchEvent(event);
    };
    this.handleEvent("toggle_scroll_lock", handleScrollLock);
  },
};

Hooks.ScrollTop = {
  mounted(this: any) {
    window.scrollTo(0, 0);
  },
};

Hooks.UpdateAlert = {
  mounted(this: any) {
    const handleUpdateAlert = ({ type, message }: any) => {
      var event = new CustomEvent("flash", {
        detail: { type: type, message: message },
      });
      window.dispatchEvent(event);
    };
    this.handleEvent("update_alert", handleUpdateAlert);
  },
};

// when user create a field in form this function will fire and send all data of form to event validate in phoenix live view
Hooks.ValidateForm = {
  mounted(this: any) {
    $(this.el).on("click", (_e: any) => {
      $(this.el).find("input").val("true");
      const { form } = ($("form#validate-form") as any).serializeJSON();
      $(this.el).find("input").val("false");
      const target = this.el.getAttribute("phx-target");
      if (target) {
        this.pushEventTo(target, "validate", { form });
      } else {
        this.pushEvent("validate", { form });
      }
    });
  },
};

Hooks.SimpleFormSubmit = {
  mounted(this: any) {
    const currentView = this;
    const form = this.el;
    form.addEventListener("submit", (event: any) => {
      event.preventDefault();
      const targetContext = form.getAttribute("phx-target") as string;
      const phxEvent = form.getAttribute("phx-submit") as string;

      currentView.liveSocket.blurActiveElement(form);

      currentView.__view.pushFormSubmit(
        form,
        +targetContext,
        phxEvent,
        undefined,
        {},
        () => {
          currentView.liveSocket.restorePreviouslyActiveFocus();
        },
      );
    });
  },
};

Hooks.UpdateNotificationBadge = {
  updated(this: any) {
    window.getNotificationBadge();
  },
};

export default Hooks;
