import $ from "jquery";
import select2, { QueryOptions } from "select2";
import { PaginatedResponse } from "./types";

/* @ts-ignore */
select2($);
export default function loadAirportOptions(): void {
  $(".airport-select").each(function (this: any) {
    const dropdownParent = $(this).data("dropdown-parent-selector") || "body";
    const theme = $(this).data("theme") || "bootstrap4";
    $(this).select2({
      placeholder: "Select",
      theme,
      ajax: {
        url: "/app/airports",
        dataType: "json",
        delay: 250,
        headers: {
          "x-csrf-token": $("meta[name='csrf-token']").attr("content"),
        },
        data: (params: QueryOptions) => ({
          name: params.term,
          page: params.page || 1,
        }),
        processResults: (data: PaginatedResponse) => ({
          results: data.entries,
          pagination: {
            more: data.total_pages > data.page_number,
          },
        }),
        cache: true,
      },
      dropdownParent: $(`${dropdownParent}`),
    });
  });
}
